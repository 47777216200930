import { Middleware } from '@nuxt/types';

const authorized: Middleware = async ({ store, $sso, $sentry, route }) => {
  try {
    // get user profile if there is not present
    if (!store.state.dashboard?.profile?.id) {
      await store.dispatch('dashboard/getProfile');
    }
    const profile = store.state.dashboard?.profile;
    // get scope from path
    for (const { scope, paths } of scopePaths) {
      for (let path of paths) {
        path = path.replace(/\//gi, '\\/');
        const pattern = new RegExp(`^${path}`, 'gi');
        // public path
        if (!pattern.test(route.path)) {
          continue;
        }
        // this path need authorization
        const ok = $sso.authorize(profile?.scopes, scope);
        if (!ok) {
          $sso.login();
        }
      }
    }
  } catch (err) {
    $sso.login();
    $sentry.captureException(err);
  }
};

const scopePaths = [{ scope: 'membersip', paths: ['/membership'] }];

export default authorized;
