
























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  layout: 'blank'
})
/**
 * Nuxt Error page
 */
export default class NuxtError extends Vue {
  scrollToTop = true;
  @Prop({ default: { statusCode: null }, type: Object }) error: any;

  logout() {
    this.$router.push({ path: '/login' });
  }
}
