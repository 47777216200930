import { ActionTree, MutationTree } from 'vuex';

export interface DashboardState {
  darkTheme: boolean;
  miniMenu: boolean;
  title: string;
  breadcrumbs: Breadcrumb[];
  profile: Profile;
}

export interface Breadcrumb {
  text: string;
  disabled: boolean;
  icon?: string;
  to?: string;
}

export interface Profile {
  id: string;
  name: string;
  email: string;
  photo: string;
  role: string;
  scopes: string[];
}

/**
 * Get default state of dashboard
 *
 * @export
 * @returns
 */
export function state(): DashboardState {
  return {
    title: 'Dashboard Administrasi',
    darkTheme: true,
    miniMenu: false,
    breadcrumbs: [rootBreadcrumb],
    profile: {
      id: null,
      name: '',
      email: '',
      photo: '',
      role: '',
      scopes: []
    }
  };
}

const rootBreadcrumb: Breadcrumb = {
  icon: 'view-dashboard',
  text: '',
  disabled: true,
  to: ''
};

export const mutations: MutationTree<DashboardState> = {
  /**
   * Toggle dark theme
   */
  toggleTheme(state: DashboardState) {
    state.darkTheme = !state.darkTheme;
  },

  /**
   * Toggle mini menu
   */
  toggleMiniMenu(state: DashboardState) {
    state.miniMenu = !state.miniMenu;
  },

  /**
   * Change dashboard title
   */
  changeTitle(state: DashboardState, title: string) {
    state.title = title;
  },

  /**
   * Change breadcrumbs state
   */
  changeBreadcrumbs(state: DashboardState, items: Breadcrumb[]) {
    const root = [rootBreadcrumb];
    state.breadcrumbs = root.concat(items);
  },

  /**
   * change user profile account
   */
  changeProfile(state: DashboardState, profile: Profile) {
    state.profile = profile;
  }
};

export const actions: ActionTree<DashboardState, DashboardState> = {
  /**
   * get user profile
   */
  async getProfile({ commit }) {
    const profile = await this.app.$sso.getProfile();
    this.$sentry.setUser({
      id: profile.id.toString(),
      email: profile.email,
      username: profile.name
    });
    commit('changeProfile', profile);
  }
};
