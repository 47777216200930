import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import InitialFilter from '~/filters/initial';
import authenticated from '~/middleware/authenticated';
import authorized from '~/middleware/authorized';
import { Profile } from '~/store/dashboard';

export interface Menu {
  id: string;
  scope: string;
  icon?: string;
  title: string;
  active?: boolean;
  to?: string;
  items?: Menu[];
}

/**
 * Default layout page
 */
@Component({
  middleware: [authenticated, authorized],
  filters: {
    initial: InitialFilter
  }
})
export default class AppLayout extends Vue {
  search = '';
  clipped = false;
  @State((state) => state.dashboard.title) title: any;
  @State((state) => state.dashboard.darkTheme) darkTheme: any;
  @State((state) => state.dashboard.miniMenu) miniMenu: any;
  @State((state) => state.dashboard.breadcrumbs) breadcrumbs: any;
  @State((state) => state.dashboard.profile) profile: Profile;
  @Mutation('dashboard/toggleTheme') toggleTheme: Function;
  @Mutation('dashboard/toggleMiniMenu') toggleMiniMenu: Function;

  leftDrawer = true;
  rightDrawer = false;
  isUpdate = false;
  buildNumber = process.env.buildNumber;

  get copyright() {
    const year = new Date().getFullYear();
    return year > 2020 ? `2020-${year}` : '2020';
  }

  get access(): string[] {
    return this.profile?.scopes || [];
  }

  /**
   * Apply dark theme programmatically
   */
  @Watch('darkTheme')
  onDarkThemeChanged(isDark: boolean) {
    this.$vuetify.theme.dark = isDark;
  }

  /**
   * listen app update
   */
  async mounted() {
    const workbox = await window['$workbox']; // eslint-disable-line
    if (workbox) {
      workbox.addEventListener('installed', (event) => {
        this.isUpdate = event?.isUpdate || false;
      });
    }
  }

  /**
   * reload PWA assets
   */
  updateApp() {
    this.isUpdate = false;
    location.reload(true);
  }

  /**
   * exit to login page
   */
  logout() {
    this.$sso.logout();
  }

  /**
   * open profile portal
   */
  profileSettings() {
    this.$sso.profileSettings();
  }

  /**
   * Filter menu
   */
  menuFilter(value: Menu[], keywords: string): Menu[] {
    const pattern = new RegExp(keywords, 'igm');

    /**
     * Filter menu
     */
    const filter = (menu: Menu[]): Menu[] => {
      return menu.filter((m) => {
        if (!this.access.includes('*')) {
          if (m.items && m.items.length) {
            return filter(m.items);
          }
          const ok = this.$sso.authorize(this.access, m.scope);
          if (!ok) {
            return false;
          }
        }
        const found = pattern.test(m.title) || pattern.test(m.to);
        if (found) {
          return true;
        }
        // check if sub-items has this keywords
        if (!m.items) {
          return false;
        }
        const items = filter(m.items);
        return items.length > 0;
      });
    };

    return filter(value);
  }

  items: Menu[] = [
    {
      id: 'membership',
      scope: 'membership',
      icon: 'mdi-account-multiple',
      title: 'Keanggotaan',
      active: true,
      items: [
        {
          id: 'membership.list',
          scope: 'membership',
          title: 'Daftar Anggota',
          to: '/membership/list'
        }
      ]
    }
  ];
}
