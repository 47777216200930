import { readFileSync } from 'fs';
import { resolve as pathResolve } from 'path';
import { NuxtConfig } from '@nuxt/types';
import colors from 'vuetify/es5/util/colors';
import dotenv from 'dotenv-defaults';

// configure dotenv
dotenv.config({
  defaults: '.env.defaults'
});

// using timestamp to semver version
const createVersion = () => {
  const now = new Date();
  const time = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
  return `${now.getFullYear()}.${now.getMonth()}.${now.getDate()}.${time}`;
};

export const CACHE_VERSION = createVersion();

// setup development TLS certs
let serverKey = '';
let serverCert = '';
if (readFileSync && pathResolve) {
  try {
    serverKey = readFileSync(pathResolve(__dirname, 'server.key'), {
      encoding: 'utf8'
    });
    serverCert = readFileSync(pathResolve(__dirname, 'server.crt'), {
      encoding: 'utf8'
    });
  } catch (err) {}
}

const config: NuxtConfig = {
  /*
   ** Nuxt rendering mode
   ** See https://nuxtjs.org/api/configuration-mode
   */
  mode: 'spa',
  server: {
    port: 7013,
    host: '0.0.0.0',
    https: {
      key: serverKey,
      cert: serverCert
    }
  },
  generate: {
    fallback: '404.html'
  },
  env: {
    buildNumber: process.env.BUILD_NUMBER || '-'
  },
  /*
   ** Nuxt target
   ** See https://nuxtjs.org/api/configuration-target
   */
  target: 'static',
  /*
   ** Headers of the page
   ** See https://nuxtjs.org/api/configuration-head
   */
  head: {
    titleTemplate: 'Dashboard Admin',
    title: 'panel administratif RS Mary Cileungsi',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        hid: 'description',
        name: 'description',
        content: process.env.npm_package_description || ''
      }
    ],
    link: [
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: `/favicon.ico?${CACHE_VERSION}`
      }
    ]
  },
  /**
   * workbox configuration
   * https://pwa.nuxtjs.org/modules/workbox.html
   */
  pwa: {
    workbox: {
      pagesURLPattern: '^((?!(_db|changes)).)*$',
      workboxURL:
        'https://storage.googleapis.com/workbox-cdn/releases/4.3.1/workbox-sw.js',
      cacheNames: {
        suffix: CACHE_VERSION
      }
    }
  },
  /*
   ** Customize the progress-bar color
   */
  loading: { color: colors.teal.accent4 },
  /*
   ** Global CSS
   */
  css: ['@/assets/style/main.scss'],
  /*
   ** Plugins to load before mounting the App
   */
  plugins: [
    '~/plugins/lodash',
    {
      src: '~/plugins/sw-hook',
      ssr: false
    }
  ],
  /*
   ** Auto import components
   ** See https://nuxtjs.org/api/configuration-components
   */
  components: [{ path: '~/components', extensions: ['vue'] }],
  /*
   ** Nuxt.js modules
   */
  modules: ['@nuxtjs/pwa', '@nuxtjs/sentry'],
  /*
   ** Nuxt.js dev-modules
   */
  buildModules: [
    '@nuxtjs/eslint-module',
    '@nuxtjs/vuetify',
    '@nuxt/typescript-build',
    '~/modules/iam-service',
    '@mary-cileungsi/sso-client',
    '@sirusdev/prescription-editor-nuxt-vuetify'
  ],
  /**
   * typesript configurations
   */
  typescript: {
    typeCheck: {
      eslint: true
    }
  },
  /**
   * sentry config
   */
  sentry: {
    config: {
      environment: process.env.SENTRY_ENV,
      release: process.env.SENTRY_RELEASE
    },
    tracing: {
      tracesSampleRate: 0.3,
      vueOptions: {
        tracing: true,
        tracingOptions: {
          hooks: ['mount', 'update'],
          timeout: 2000,
          trackComponents: true
        }
      },
      browserOptions: {
        tracingOrigins: [process.env.IAM_BASE_URL]
      } as any
    }
  },
  /**
   * IAM service configuration
   */
  iam: {
    baseUrl: process.env.IAM_BASE_URL,
    graphqlSubscriptionUrl: process.env.IAM_GRAPHQL_SUBSCRIPTION_URL
  },
  /**
   * SSO library configuration
   */
  sso: {
    apiBaseUrl: process.env.IAM_BASE_URL,
    portalBaseUrl: process.env.IAM_PORTAL_BASE_URL
  },
  /*
   ** vuetify module configuration
   ** https://github.com/nuxt-community/vuetify-module
   */
  vuetify: {
    customVariables: ['~/assets/style/variables.scss'],
    defaultAssets: {
      font: {
        family: 'Roboto'
      },
      icons: 'mdi'
    },
    optionsPath: './vuetify.options.ts'
  },
  /*
   ** Build configuration
   */
  build: {
    /**
     * You can extend webpack config here
     *
     */
    extend(config, { isClient }) {
      // Extend only webpack config for client-bundle
      if (isClient) {
        config.devtool = '#source-map';
      }
      config.node = {
        fs: 'empty'
      };
      if (
        config.module.rules.find((r) => {
          if (!r.loader) {
            return false;
          }
          return /graphql/.test(r.loader as string);
        })
      ) {
        return;
      }
      config.module.rules.push({
        test: /\.(graphql|gql)$/,
        loader: 'graphql-tag/loader'
      });
    }
  }
};

export default config;
